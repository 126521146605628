import APIService from './APIService';

export default {
  getAll() {
    return APIService.apiCall().get('/faseamento/get-all');
  },
  executar(id) {
    return APIService.apiCall().get(`/faseamento/executar/${id}`);
  },
  deletar(id) {
    return APIService.apiCall().get(`/faseamento/excluir/${id}`);
  },
  save(postData) {
    return APIService.apiCall().post(
      '/faseamento/save',
      JSON.stringify(postData)
    );
  },
  baixarLogsTecnicos(id) {
    return APIService.apiCall().get(`/faseamento/logs-tecnicos/${id}`, {
      responseType: 'blob',
      ... {
        timeout: 60 * 60 * 1000
      }
    });
  }
};