<template>
  <v-container
    fluid
    tag="section"
  >
    <material-wizard
      title="Executar Algoritmo de Faseamento"
      subtitle="Selecione a versão da BDGD e CTMTS"
      v-model="tab"
      :available-steps="tabsLiberadas"
      :tabs="tabs"
      class="mx-auto"
      @click:next="next()"
      @click:prev="back()"
      :loading="loading"
    >
      <faseamento-wizard-tabs
        ref="faseamentoParametros"
        @step-observer="updateTabStatus($event)"
      />
    </material-wizard>
  </v-container>
</template>

<script>
import FaseamentoService from '@/services/FaseamentoService';
import FaseamentoWizardTabs from '@/components/perdas-tecnicas/faseamento/FaseamentoWizardTabs';
import MaterialWizard from '@/components/base/MaterialWizard';
import wizardMixins from '@/mixins/wizardMixins';
import routes from '@/store/modules/routes';

export default {
  mixins: [wizardMixins],
  components: {
    FaseamentoWizardTabs,
    MaterialWizard
  },
  data: () => ({
    tabs: ['Mês dos Dados', 'CTMTs', 'Confirmação'],
    loading: false
  }),
  methods: {
    save() {
      const data = this.$refs.faseamentoParametros.exportData();
      this.loading = true;
      FaseamentoService.save(data)
        .then(() => {
          this.$toast.success('Execução de faseamento salva com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['FASEAMENTO']
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar a execução de faseamento.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
